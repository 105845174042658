<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <div style="height: 3rem"></div>
    <van-tabbar fixed v-model="active" v-show="$route.meta.parent != ''&& !$store.getters.getIsXunjianAPP">
      <van-tabbar-item name="mQuery" icon="apps-o" is-link to="/query/index">{{
        $t("lang.menu_query")
      }}</van-tabbar-item>
      <van-tabbar-item
        name="mRepair"
        icon="warn-o"
        is-link
        :to="{ name: 'RepairIndex', params: { p: { pagesize: 1 } } }"
        >{{ $t("lang.menu_repair") }}</van-tabbar-item
      >
      <van-tabbar-item
        name="mTotal"
        icon="todo-list-o"
        is-link
        to="/total/index"
        >{{ $t("lang.menu_report") }}</van-tabbar-item
      >
      <van-tabbar-item
        name="mUser"
        icon="user-circle-o"
        is-link
        to="/user/personal"
        >{{ $t("lang.menu_personalcenter") }}</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<style scoped>
/deep/ .van-tabbar,
.van-tabbar-item {
  color: #fff;
  background-color: #336666;
}
/deep/ .van-tabbar-item--active {
  color: rgb(255, 255, 0);
  background-color: #336666; /* #f8f8f8ee;*/
}
</style>
<script>
export default {
  data() {
    return {
      active: "mQuery",
    };
  },
  created() {
    document.title = this.$t("lang.apptitle");
  },

  /*
  mounted() {
    window.addEventListener(
      "hashchange",
      () => {
        var currentPath = window.location.hash.slice(1); // 获取输入的路由
        if (this.$router.path !== currentPath) {
          this.$router.push(currentPath); // 动态跳转
        }
      },
      false
    );
  },
  */
};
</script>
