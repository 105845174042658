import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n';
import Utils from '@/utils/utils.js'  //import 'vant/lib/icon/local.css';
import 'vant/lib/index.css';
import 'vant/lib/icon/local.css';     //利用本地logo字体

//import Config from '@/utils/config.js'
import { Locale } from 'vant';
import zhCN from 'vant/es/locale/lang/zh-CN';
import zhTW from 'vant/es/locale/lang/zh-TW';
import enUS from 'vant/es/locale/lang/en-US';
import idID from 'vant/es/locale/lang/id-ID';

//import VueAMap from 'vue-amap'

import {
  Tabbar, TabbarItem, Tab, Tabs, NavBar, Popup, Grid, GridItem, Cell, CellGroup, Field, Form, Button, IndexBar, IndexAnchor,
  Icon, Toast, Panel, Loading, Col, Row, Image as VanImage, Switch, RadioGroup, Radio, Tag,
  Dialog, Picker, Search, List, Checkbox, CheckboxGroup, Step, Steps, DatetimePicker, Sticky, PullRefresh, Uploader
} from 'vant';

Vue.config.productionTip = false

Vue.use(VueI18n);
Vue.use(Grid).use(GridItem).use(Popup).use(Tab).use(Tabs).use(NavBar).use(Cell).use(CellGroup);
Vue.use(Loading).use(Tabbar).use(TabbarItem).use(Field).use(Form).use(Button).use(RadioGroup).use(Radio);
Vue.use(Icon).use(Panel).use(Col).use(Row).use(VanImage).use(Switch).use(Tag);
Vue.use(IndexBar).use(IndexAnchor).use(Dialog).use(Picker).use(Search).use(List).use(Checkbox);
Vue.use(Step).use(Steps).use(DatetimePicker).use(CheckboxGroup).use(Sticky).use(PullRefresh).use(Uploader);
Vue.use(Utils);
//Vue.use(VueAMap);

Vue.prototype.$Toast = Toast;
//Vue.prototype.$Config = Config;
Vue.prototype.$Locale = Locale;

let lang = localStorage.getItem('locale') || 'zh-CN' || 'ZH';
let tmpUrl = window.location.href.split('?')[1];
let tmpQuery = new URLSearchParams('?' + tmpUrl);
let tmpLocal = tmpQuery.get('I18N');
if (tmpLocal != null) lang = tmpLocal;
if (lang == "undefined") lang = "zh-CN";
localStorage.setItem('locale', lang);

const i18n = new VueI18n({
  locale: lang,
  messages: {
    'zh-CN': require('@/assets/lang/zh-CN.js'),
    'zh-TW': require('@/assets/lang/zh-TW.js'),
    'en-US': require('@/assets/lang/en-US.js'),
    'id-ID': require('@/assets/lang/id-ID.js'),
  }
});

if (lang == "en-US") Locale.use("en-US", enUS);
else if (lang == "zh-TW") Locale.use("zh-TW", zhTW);
else if (lang == "id-ID") Locale.use("id-ID", idID);
else Locale.use("zh-CN", zhCN);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');


router.beforeEach((to, from, next) => {

  //对特定路由不拦截
  if (to.name === 'Login' || to.name === 'RepairLoginDetail' || to.name === "Register" || to.name === "Adapter" || to.name === "404" || to.name === "Test") {
    next()
    return
  }
  next();

  /*
  //用户登录检查
  if (!store.getters.getToken) {
    Dialog.alert({
      message: i18n.t('lang.hint_relogin'),
    }).then(() => {
      window.location.href = router.app.$Config.ClientUrl();
    });
  } else {
    next()
  }
  */
})
