export default {
    install(Vue) {
        Vue.prototype.$Log = {
            info: function (tag, msg) {
                if (process.env.NODE_ENV == "development") {
                    console.log("OutputInfo [" + tag + "]:", msg);
                }
            },
            error: function (tag, msg) {
                if (process.env.NODE_ENV == "development") {
                    console.log("OutputErr [" + tag + "]:", msg);
                }
            }
        },

            Vue.prototype.$Tools = {
                FormatDate: function (fmt, date) {
                    let ret;
                    const opt = {
                        "Y+": date.getFullYear().toString(),        // 年
                        "m+": (date.getMonth() + 1).toString(),     // 月
                        "d+": date.getDate().toString(),            // 日
                        "H+": date.getHours().toString(),           // 时
                        "M+": date.getMinutes().toString(),         // 分
                        "S+": date.getSeconds().toString()          // 秒
                    };
                    for (let k in opt) {
                        ret = new RegExp("(" + k + ")").exec(fmt);
                        if (ret) {
                            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
                        }
                    }
                    return fmt;
                },
                FormatDate1: function (longdate) {
                    var date = new Date(longdate);
                    return this.FormatDate("YYYY-mm-dd HH:MM:SS", date);
                },
                FormatDate2: function (date) {
                    return `${date.getYear() + 1900}-${date.getMonth() + 1
                        }-${date.getDate()}`;
                },
                getCurrDate: function () {
                    return this.FormatDate('YYYY-mm-dd', new Date());
                },
                getDateStr(d) {
                    return d == null ? "" : d.replace("T", " ").substring(0, 16);
                },
                getDateStr1(d) {
                    return d == null ? "" : d.replace("T", " ").substring(0, 10);
                },
                formatNumber: function (num) {
                    return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
                },
                getBoolVal: function (obj) {
                    if (obj) return obj;
                    else return false;
                },
                sortBykey(ary, key) {
                    return ary.sort(function (a, b) {
                        let x = a[key];
                        let y = b[key];
                        return ((x < y) ? -1 : (x > y) ? 1 : 0)
                    })
                }

            }
    }
}
