import Vue from 'vue'
import VueRouter from 'vue-router'
//import store from '../store/index'
//import { Dialog } from "vant";

Vue.use(VueRouter)

const routes = [

  //User---------------------------------------------------------------------------

  { path: '/', redirect: '/user/login' },
  {
    path: '/user/login',
    name: 'Login',
    component: () => import('@/views/user/login.vue'),
    meta: {
      title: 'lang.menu_userbind',
      parent: "",
      keepAlive: false
    }
  },
  {
    path: '/user/qylogin',
    name: 'QYLogin',
    component: () => import('@/views/user/qylogin.vue'),
    meta: {
      title: 'lang.menu_userbind',
      parent: "",
      keepAlive: false
    }
  },
  {
    path: '/user/register',
    name: 'Register',
    component: () => import('@/views/user/register.vue'),
    meta: {
      title: 'lang.menu_userregister',
      parent: "",
      keepAlive: false
    }
  },
  {
    path: '/user/personal',
    name: 'Personal',
    component: () => import('@/views/user/personal.vue'),
    meta: {
      title: 'lang.menu_personalcenter',
      parent: "mUser",
      keepAlive: false
    }
  },
  {
    path: '/user/changepwd',
    name: 'ChangePwd',
    component: () => import('@/views/user/changepwd.vue'),
    meta: {
      title: 'lang.menu_changepwd',
      parent: "mUser",
      keepAlive: false
    }
  },
  {
    path: '/user/line',
    name: 'Line',
    component: () => import('@/views/user/line.vue'),
    meta: {
      title: 'lang.menu_myrouter',
      parent: "mUser",
      keepAlive: true
    }
  },
  {
    path: '/user/department',
    name: 'Department',
    component: () => import('@/views/user/department.vue'),
    meta: {
      title: 'lang.menu_mydept',
      parent: "mUser",
      keepAlive: true
    }
  },
  {
    path: '/user/adapter',
    name: 'Adapter',
    component: () => import('@/views/user/adapter.vue'),
    meta: {
      title: 'lang.menu_adapter',
      parent: "",
      keepAlive: false
    }
  },
  {
    path: '/user/test',
    name: 'Test',
    component: () => import('@/views/user/test.vue'),
    meta: {
      title: 'lang.menu_adapter',
      parent: "",
      keepAlive: false
    }
  },

  //Xunjian APP Query
  { path: '/', redirect: '/user/login_xunjian' },
  {
    path: '/user/login_xunjian',
    name: 'LoginXunjian',
    component: () => import('@/views/user/login_xunjian.vue'),
    meta: {
      title: 'lang.menu_userbind',
      parent: "",
      keepAlive: false
    }
  },
  {
    path: '/user/adapter_xunjian',
    name: 'AdapterXunjian',
    component: () => import('@/views/user/adapter_xunjian.vue'),
    meta: {
      title: 'lang.menu_adapter',
      parent: "",
      keepAlive: false
    }
  },
  {
    path: '/query/appindex',
    name: 'QueryAppIndex',
    component: () => import('@/views/query/appindex.vue'),
    meta: {
      title: 'lang.menu_queryreport',
      parent: "",
      keepAlive: false
    }
  },

  //Query---------------------------------------------------------------------------
  {
    path: '/query/index',
    name: 'QueryIndex',
    component: () => import('@/views/query/index.vue'),
    meta: {
      title: 'lang.menu_queryreport',
      parent: "mQuery",
      keepAlive: false
    }
  }
  ,
  {
    path: '/query/pollingquery',
    name: 'PollingQuery',
    component: () => import('@/views/query/pollingquery.vue'),
    meta: {
      title: 'lang.menu_patrolrecord',
      parent: "mQuery",
      keepAlive: true
    }
  }
  ,
  {
    path: '/query/missrecordaudit',
    name: 'MissRecordAudit',
    component: () => import('@/views/query/missrecordaudit.vue'),
    meta: {
      title: 'lang.menu_patrolmissrecord',
      parent: "mQuery",
      keepAlive: true
    }
  }

  ,
  {
    path: '/query/pollingquerydetail',
    name: 'PollingQueryDetail',
    component: () => import('@/views/query/pollingquerydetail.vue'),
    meta: {
      title: 'lang.menu_patrolrecord',
      parent: "mQuery",
      keepAlive: true
    }
  }
  ,
  {
    path: '/query/pollingplan',
    name: 'PollingPlan',
    component: () => import('@/views/query/pollingplan.vue'),
    meta: {
      title: 'lang.menu_patrolplanrecord',
      parent: "mQuery",
      keepAlive: true
    }
  }
  ,

  {
    path: '/query/pollingplandetail',
    name: 'PollingPlanDetail',
    component: () => import('@/views/query/pollingplandetail.vue'),
    meta: {
      title: 'lang.menu_patrolplanrecord',
      parent: "mQuery",
      keepAlive: true
    }
  }
  ,
  {
    path: '/query/itemquery',
    name: 'ItemQuery',
    component: () => import('@/views/query/itemquery.vue'),
    meta: {
      title: 'lang.menu_patrolevent',
      parent: "mQuery",
      keepAlive: true
    }
  }
  ,
  {
    path: '/query/detail',
    name: 'QueryDetail',
    component: () => import('@/views/query/detail.vue'),
    meta: {
      title: 'lang.menu_patroldetails',
      parent: "mQuery",
      keepAlive: false
    }
  }
  ,
  {
    path: '/query/map',
    name: 'Map',
    component: () => import('@/views/query/map.vue'),
    meta: {
      title: 'lang.menu_patrolmap',
      parent: "mQuery",
      keepAlive: true
    }
  }
  ,
  {
    path: '/query/currlocal',
    name: 'CurrLocal',
    component: () => import('@/views/query/currlocal.vue'),
    meta: {
      title: 'lang.menu_currlocal',
      parent: "mQuery",
      keepAlive: true
    }
  }
  ,
  {
    path: '/query/temppatrolquery',
    name: 'TempPatrolQuery',
    component: () => import('@/views/query/temppatrolquery.vue'),
    meta: {
      title: 'lang.menu_temppatrolquery',
      parent: "mQuery",
      keepAlive: true
    }
  }
  ,

  {
    path: '/query/temppatroldetail',
    name: 'TempPatrolDetail',
    component: () => import('@/views/query/temppatroldetail.vue'),
    meta: {
      title: 'lang.menu_temppatroldetail',
      parent: "mQuery",
      keepAlive: false
    }
  }
  //Wechat---------------------------------------------------------------------------
  ,
  {
    path: '/wechat/logindetail',
    name: 'WechatLoginDetail',
    component: () => import('@/views/wechat/logindetail.vue'),
    meta: {
      title: 'lang.menu_repair',
      parent: "",
      keepAlive: false
    }
  }
  ,
  {
    path: '/wechat/loginmissrecord',
    name: 'LoginMissRecord',
    component: () => import('@/views/wechat/loginmissrecord.vue'),
    meta: {
      title: 'lang.menu_missrecord',
      parent: "",
      keepAlive: false
    }
  }
  ,
  {
    path: '/wechat/missrecord',
    name: 'WechatMissRecord',
    component: () => import('@/views/wechat/missrecord.vue'),
    meta: {
      title: 'lang.menu_missrecord',
      parent: "mQuery",
      keepAlive: true
    }
  }
  //Repair-------------------------------------------------------------------
  ,
  {
    path: '/repair/index',
    name: 'RepairIndex',
    component: () => import('@/views/repair/index.vue'),
    meta: {
      title: 'lang.menu_repair',
      parent: "mRepair",
      keepAlive: true
    }
  }
  ,
  {
    path: '/repair/logindetail',
    name: 'RepairLoginDetail',
    component: () => import('@/views/repair/logindetail.vue'),
    meta: {
      title: 'lang.menu_repair',
      parent: "",
      keepAlive: false
    }
  }
  ,
  {
    path: '/repair/detail',
    name: 'RepairDetail',
    component: () => import('@/views/repair/detail.vue'),
    meta: {
      title: 'lang.menu_repairdetails',
      parent: "mRepair",
      keepAlive: false
    }
  }
  ,
  {
    path: '/repair/abnormaldetail',
    name: 'AbnormalDetail',
    component: () => import('@/views/repair/abnormaldetail.vue'),
    meta: {
      title: 'lang.menu_abnormaldetails',
      parent: "mRepair",
      keepAlive: false
    }
  }
  ,

  //Total-------------------------------------------------------------------
  {
    path: '/total/index',
    name: 'TotalIndex',
    component: () => import('@/views/total/index.vue'),
    meta: {
      title: 'lang.menu_statistics',
      parent: "mTotal",
      keepAlive: false
    }
  }
  ,
  {
    path: '/total/pass',
    name: 'Pass',
    component: () => import('@/views/total/pass.vue'),
    meta: {
      title: 'lang.menu_passrate',
      parent: "mTotal",
      keepAlive: true
    }
  }
  ,
  {
    path: '/total/unusual',
    name: 'Unusual',
    component: () => import('@/views/total/unusual.vue'),
    meta: {
      title: 'lang.menu_abnormal',
      parent: "mTotal",
      keepAlive: true
    }
  },

  //Other-------------------------------------------------------------------
  {
    path: '/common/queryform',
    name: 'QueryForm',
    component: () => import('@/views/common/queryform.vue'),
    meta: {
      title: 'lang.menu_query',
      parent: "",
      keepAlive: false
    }
  }
  ,
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: {
      title: "404",
      parent: "",
    }
  }
]

const router = new VueRouter({
  mode: 'hash',  //'hash',history
  routes
})

/*
router.beforeEach((to, from, next) => {

  //if (!/micromessenger/i.test(navigator.userAgent)) {
  //  next()
  //  return
  //}

  //对特定路由不拦截
  if (to.name === 'Login' || to.name === 'RepairLoginDetail' || to.name === "Register" || to.name === "Adapter" || to.name === "404") {
    next()
    return
  }


  if ((from.name === 'PollingQueryDetail' && to.name === 'PollingQuery')
    || (from.name === 'PollingPlanDetail' && to.name === 'PollingPlan')
    || (from.name === 'QueryForm' && to.name === 'PollingQuery' && to.params.p == undefined)
    || (from.name === 'QueryForm' && to.name === 'PollingPlan' && to.params.p == undefined)
    || (from.name === 'QueryForm' && to.name === 'ItemQuery' && to.params.p == undefined) 
    || (from.name === 'QueryForm' && to.name === 'Pass' && to.params.p == undefined) 
    || (from.name === 'QueryForm' && to.name === 'Unusual' && to.params.p == undefined)    
  ) {
    to.meta.keepAlive = true;
  } else {
    to.meta.keepAlive = false;
  }


  //用户登录检查
  if (!store.getters.getToken) {
    Dialog.alert({
      message: this.$t('lang.hint_relogin'),
    }).then(() => {
      window.location.href = router.app.$Config.ClientUrl();
    });
  } else {
    next()
  }
})
*/

export default router
