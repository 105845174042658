import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    openid: null,
    faceurl: null,
    companyid: null,
    corpid: null,

    token: null,
    user: null,

    companys: null,
    depts: null,
    group: null,
    inspector: null,
    permission: null,
    place: null,
    routes: null,
    devices:null,

    reload: null,
    isXunjianApp: null
  },

  mutations: {
    setOpenID(state, openid) {
      sessionStorage.setItem("openid", openid);
      state.openid = openid;
    },
    setCorpID(state, corpid) {
      sessionStorage.setItem("corpid", corpid);
      state.corpid = corpid;
    },
    setFaceUrl(state, faceurl) {
      sessionStorage.setItem("faceurl", faceurl);
      state.faceurl = faceurl;
    },
    setCompanyId(state, companyid) {
      sessionStorage.setItem("companyid", companyid);
      state.companyid = companyid;
    },
    setToken(state, token) {
      sessionStorage.setItem("token", token);
      state.token = token;
    },
    setUser(state, user) {
      sessionStorage.setItem("user", JSON.stringify(user));
      state.user = user;
    },

    setCompanys(state, companys) {
      sessionStorage.setItem("lstCompanys", JSON.stringify(companys));
      state.companys = companys;
    },
    setDepts(state, depts) {
      sessionStorage.setItem("lstDepts", JSON.stringify(depts));
      state.depts = depts;
    },
    setGroup(state, group) {
      sessionStorage.setItem("lstGroup", JSON.stringify(group));
      state.group = group;
    },
    setInspector(state, inspector) {
      sessionStorage.setItem("lstInspector", JSON.stringify(inspector));
      state.inspector = inspector;
    },
    setPermission(state, permission) {
      sessionStorage.setItem("lstPermission", JSON.stringify(permission));
      state.permission = permission;
    },
    setPlace(state, place) {
      sessionStorage.setItem("lstPlace", JSON.stringify(place));
      state.place = place;
    },
    setRoutes(state, routes) {
      sessionStorage.setItem("lstRoutes", JSON.stringify(routes));
      state.routes = routes;
    },
    setDevices(state, devices) {
      sessionStorage.setItem("lstDevices", JSON.stringify(devices));
      state.devices = devices;
    },

    setReload(state, flag) {
      sessionStorage.setItem("Reload", flag);
      state.reload = flag;
    },

    setIsXunjianApp(state, flag) {
      sessionStorage.setItem("isXunjianApp", flag);
      state.isXunjianApp = flag;
    }

  },

  getters: {
    getOpenID: state => {
      return (state.openid != null ? state.openid : sessionStorage.getItem("openid"));
    },
    getFace: state => {
      return (state.faceurl != null && state.faceurl != "") ? state.faceurl : require("@/assets/boy.png");
    },
    getCompanyId: state => {
      return (state.companyid != null ? state.companyid : sessionStorage.getItem("companyid"));
    },
    getCorpID: state => {
      return (state.corpid != null ? state.corpid : sessionStorage.getItem("corpid"));
    },
    getToken: state => {
      return (state.token != null ? state.token : sessionStorage.getItem("token"));
    },
    getUser: state => {
      return (state.user != null ? state.user : sessionStorage.getItem("user"));
    },

    getCompanys: state => {
      return (state.companys != null ? state.companys : JSON.parse(sessionStorage.getItem("lstCompanys")));
    },
    getDepts: state => {
      return (state.depts != null ? state.depts : JSON.parse(sessionStorage.getItem("lstDepts")));
    },
    getGroup: state => {
      return (state.group != null ? state.group : JSON.parse(sessionStorage.getItem("lstGroup")));
    },
    getInspector: state => {
      return (state.inspector != null ? state.inspector : JSON.parse(sessionStorage.getItem("lstInspector")));
    },
    getPermission: state => {
      return (state.permission != null ? state.permission : JSON.parse(sessionStorage.getItem("lstPermission")));
    },
    getPlace: state => {
      return (state.place != null ? state.place : JSON.parse(sessionStorage.getItem("lstPlace")));
    },
    getRoutes: state => {
      return (state.routes != null ? state.routes : JSON.parse(sessionStorage.getItem("lstRoutes")));
    },
    getDevices: state => {
      return (state.devices != null ? state.devices : JSON.parse(sessionStorage.getItem("lstDevices")));
    },

    getReload: state => {
      return (state.reload != null ? state.reload : sessionStorage.getItem("Reload"));
    },

    getIsXunjianAPP: state => {
      return (state.isXunjianApp != null ? state.isXunjianApp : sessionStorage.getItem("isXunjianApp"));
    }

  }
})
